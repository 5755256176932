.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}