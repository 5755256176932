html {
  height: 100%;
  overflow: auto;
}

body {
  background: linear-gradient(#684092, #48276C);
  background-repeat: no-repeat;
  height: 100%;
  overflow: auto;
  font-family: $font-family-base;
}

*, ::after, ::before {
  box-sizing: border-box;
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
}

.inverted {
  color: $color-text-input-inverted;
}
